import React, { useContext, useEffect, useState } from "react";
import WorkAllocationHeader from "./workHeader";
import { Avatar, AvatarGroup, Box, Card, Drawer } from "@mui/material";
import RangeDatePicker from "../../components/RangeDatePicker";
import { GlobalData } from "../../context/globalData";
import WorkAllocationTrend from "./workAllocationTrend";
import { datePayload } from "../../utils/moduleTabApi";
import instance from "../../utils/axiosHelper";
import WorkIssueLevel from "./workIssueLevel";
import { config } from "../../Config/permission";

const WorkAllocation = () => {
  const { projectRangePickerValue } = useContext(GlobalData);
  const [date, setDate] = useState(projectRangePickerValue);
  const [workAllocationData, setWorkAllocationData] = useState({});
  const [IssueTypeTrend, setIssueTypeTrend] = useState([]);
  const [issueType, setIssueType] = useState("Epic");
  const [issueLevelData, setIssueLevelData] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState("Epic");
  const [selectedIteration, setSelectedIteration] = useState(7);
  const [workAllocationTrendData, setWorkAllocationTrendData] = useState([]);
  const [showCostPopup, setShowCostPopup] = useState(null);
  const [constAmt, setConstAmt] = useState("");
  const [allOverAmt, setAllOverAmt] = useState();
  const [showCostPopupIssueLevel, setShowCostPopupIssueLevel] = useState(null);
  const [constAmtIssueLevel, setConstAmtIssueLevel] = useState("");
  const [allOverAmtIssueLevel, setAllOverAmtIssueLevel] = useState();
  const [isSummary, setIsSummary] = useState(false);

  var colors = [
    "#5EBDCC",
    "#8DDBE8",
    "#9DE6F2",
    "#2E7E8B",
    "#1B515A",
    "#33FFFF",
  ];

  // Array of IssuesList
  const IssuesList = [
    { label: "Epic", value: "Epic" },
    { label: "Project", value: "Project" },
    { label: "Issue", value: "Issue" },
  ];

  // iteration of Trend
  const iteration = [
    { key: "Weekly", value: 7 },
    { key: "Monthly", value: 30 },
  ];

  // Total Cost of header
  const handleShowCost = (event) => {
    setShowCostPopup(event?.currentTarget);
  };

  const handleClosePopup = () => {
    setShowCostPopup(null);
  };

  const ApplyIssueHandler = () => {
    setAllOverAmt(constAmt);
    setShowCostPopup();
  };

  // Total Cost of header
  const handleShowCostIssueLevel = (event) => {
    setShowCostPopupIssueLevel(event?.currentTarget);
  };

  const handleClosePopupIssueLevel = () => {
    setShowCostPopupIssueLevel(null);
  };

  const ApplyIssueHandlerIssueLevel = () => {
    setAllOverAmtIssueLevel(constAmtIssueLevel);
    setShowCostPopupIssueLevel(null);
  };

  // Header
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resourceallocationClient/getProjects?${dateUrl}`;
    if (allOverAmt) {
      url += `&cost=${allOverAmt}`;
    }
    instance
      .get(url)
      .then((response) => {
        setWorkAllocationData(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [date, allOverAmt]);

  // Issue Type Trend
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resourceallocationClient/getIssueTypes?${dateUrl}`;
    instance
      .get(url)
      .then((response) => {
        const newResponse = response?.data.map((item) => {
          return {
            country: item?._id,
            litres: item?.Percentage,
            additionalInfo: `${item?.StoryPont} Issues Of Type:  ${item?._id} (${item?.Percentage}%)`,
          };
        });
        setIssueTypeTrend(newResponse);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [date]);

  // Allocation Trend
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resourceallocationClient/getTrendsGraph?${dateUrl}&type=${selectedIssue}&iteration=${selectedIteration}`;
    instance
      .get(url)
      .then((response) => {
        setWorkAllocationTrendData(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [date, selectedIssue, selectedIteration]);

  // Issue level Table
  useEffect(() => {
    const dateUrl = datePayload(date);
    let url = `/api/v2/resourceallocationClient/getIssueLevel?${dateUrl}&type=${issueType}`;
    if (allOverAmtIssueLevel) {
      url += `&cost=${allOverAmtIssueLevel}`;
    }
    instance
      .get(url)
      .then((response) => {
        setIssueLevelData(response?.data);
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }, [date, issueType, allOverAmtIssueLevel]);

  const getAvatar = (name) => {
    const words = name.split(" ");
    const firstLetter = words[0].charAt(0);
    const secondLetter = words.length > 1 ? words[1].charAt(0) : "";

    return firstLetter + secondLetter;
  };

  // Columns of Issue Level
  const issueLevelTable = [
    {
      title: "Key",
      field: "Key",
      render: (row) => (
        <a href={row?.TaskLink} target="_blank" rel="noreferrer">
          {row?.Key}
        </a>
      ),
    },
    { title: "Name", field: "Name" },
    {
      title: "Assignee",
      field: "",
      render: (row) => (
        <>
          {issueType === "Issue" ? (
            row?.Assignee
          ) : (
            <Box>
              <AvatarGroup
                total={row?.UniqueResource?.length || 0}
                max={4}
                sx={{ justifyContent: "flex-end" }}
              >
                {row?.UniqueResource?.map((e, i) => (
                  <Avatar sx={{ bgcolor: colors[i] }} key={i}>
                    {getAvatar(e)}
                  </Avatar>
                ))}
              </AvatarGroup>
            </Box>
          )}
        </>
      ),
    },
    {
      title: `Scope (${config.type})`,
      field: "StoryPoint",
    },
    {
      title: "% Of Total Work",
      field: "AllocationPercent",
      render: (row) => <>{row?.AllocationPercent} %</>,
    },
    {
      title: "Effort (FTE)",
      field: "TotalFte",
      render: (row) => <>{row?.TotalFte} FTE</>,
    },
    {
      title: "Effort (%)",
      field: "EffortPercentage",
      render: (row) => <>{row?.EffortPercentage} %</>,
    },
    // {
    //   title: "$ Cost",
    //   field: "Cost",
    //   render: (row) => <>$ {row?.Cost}</>,
    // },
  ];

  if (issueType === "Project") {
    issueLevelTable.push(
      {
        title: "Epic (Count)",
        field: "UniqueEpics",
        render: (row) => <>{row?.UniqueEpics?.length} </>,
      },
      {
        title: "Issue Without Epic",
        field: "UniqueEpics",
        render: (row) => <>{row?.WithoutEpic} </>,
      }
    );
  } else if (issueType === "Issue") {
    issueLevelTable.push(
      {
        title: "Created Date",
        field: "CreatedDate",
      },
      {
        title: "Updated Time",
        field: "UpdatedDate",
      },
      {
        title: "Status",
        field: "Status",
        render: (row) => <>{row?.Status} </>,
      }
    );
  }
  return (
    <div>
      <Box display={"flex"} gap={2} mb={2}>
        {/* <EpicDropDown epicDropDownHandler={epicDropDownHandler} />  */}
        <>
          {/* select dropdown of month */}
          {/* <div>
              <NormalSelect
                option={[
                  { label: "Monthly", value: 30 },
                  { label: "Biweekly", value: 14 },
                  { label: "Weekly", value: 7 },
                  // { label: "Daily", value: 1 },  
                ]}
                value={iteration}
                handleChange={(value) => setIteration(value)}
              />
            </div> */}
          <div>
            <RangeDatePicker setDate={setDate} date={date} isNextDate={false} />
          </div>
          <div onClick={() => setIsSummary(!isSummary)}>
            <div className="border px-3 pt-2 pb-3 rounded cursorPointer mx-3">
              Summary
            </div>
          </div>
        </>
      </Box>
      <Box mb={3}>
        <WorkAllocationHeader
          data={workAllocationData}
          showCostPopup={showCostPopup}
          showCost={handleShowCost}
          handleClose={handleClosePopup}
          setConstAmt={setConstAmt}
          constAmt={constAmt}
          ApplyIssueHandler={ApplyIssueHandler}
        />
      </Box>
      <Box mb={2}>
        <WorkAllocationTrend
          data={IssueTypeTrend}
          setSelectedIssue={setSelectedIssue}
          selectedIssue={selectedIssue}
          setSelectedIteration={setSelectedIteration}
          selectedIteration={selectedIteration}
          iteration={iteration}
          workAllocationTrendData={workAllocationTrendData}
          date={date}
        />
      </Box>
      <Card className="p-2">
        <WorkIssueLevel
          setIssueType={setIssueType}
          issueType={issueType}
          columnList={issueLevelTable}
          columnData={issueLevelData}
          IssuesList={IssuesList}
          showCostPopup={showCostPopupIssueLevel}
          showCost={handleShowCostIssueLevel}
          handleClose={handleClosePopupIssueLevel}
          setConstAmt={setConstAmtIssueLevel}
          constAmt={constAmtIssueLevel}
          ApplyIssueHandler={ApplyIssueHandlerIssueLevel}
        />
      </Card>

      <Drawer
        anchor={"right"}
        open={isSummary}
        onClose={() => setIsSummary(false)}
      >
        <div style={{ padding: "20px", width: "500px" }}>
          <h4 className="text-center mb-4 border-bottom pb-3 border-dark">
            Resource Allocation Analysis Report
          </h4>

          <section style={{ marginBottom: "20px" }}>
            <h5>Summary</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Total Projects: <strong>1</strong>
              </li>
              <li>
                Total Resources: <strong>27</strong>
              </li>
              <li>
                Total FTE: <strong>71.47</strong>
              </li>
              <li>
                Total Cost: <strong>$7147</strong>
              </li>
            </ul>
          </section>

          <section style={{ marginBottom: "20px" }}>
            <h5>Issue Analysis:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Story: <strong>311 story points</strong>,{" "}
                <strong>34% of total time spent</strong>.
              </li>
              <li>
                New Feature: <strong>68 story points</strong>,{" "}
                <strong>21% of total time spent</strong>.
              </li>
              <li>
                Bug: <strong>111 story points</strong>,{" "}
                <strong>15% of total time spent</strong>.
              </li>
              <li>
                Improvement: <strong>250 story points</strong>,{" "}
                <strong>32% of total time spent</strong>.
              </li>
            </ul>
          </section>

          <section style={{ marginBottom: "20px" }}>
            <h5>Epic Trend Analysis:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Data from 2024-12-09 to 2024-12-30 indicates significant
                fluctuations in activity.
              </li>
              <li>
                Notable spikes in ZUZU-3380 and ZUZU-9297 during later weeks.
              </li>
            </ul>
          </section>

          <section style={{ marginBottom: "20px" }}>
            <h5>Inferences:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                The majority of time is allocated to Story and Improvement,
                indicating these areas are the primary focus.
              </li>
              <li>
                Bug fixes constitute a smaller percentage, suggesting stable
                software but requiring continued monitoring.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>Alerts:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                ZUZU-3380 shows a sharp increase in activity on 2024-12-23,
                requiring evaluation for resource adequacy.
              </li>
              <li>
                High activity in ZUZU-9297 during the last week may need
                attention to prevent burnout or inefficiencies.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>Impact Analysis:</h5>
            <ul style={{ fontSize: "16px" }}>
              <li>
                Current resource allocation seems sufficient, but the rising
                trends in certain epics could lead to overutilization if
                unaddressed.
              </li>
              <li>
                Budget utilization is in line with resource deployment,
                indicating efficient use of funds.
              </li>
            </ul>
          </section>
          <section style={{ marginBottom: "20px" }}>
            <h5>Recommendations:</h5>
            <oi style={{ fontSize: "16px" }}>
              <li>
                Monitor ZUZU-3380 and ZUZU-9297 to ensure balanced workload.
              </li>
              <li>
                Reevaluate priority distribution, especially in Improvement and
                New Feature areas.
              </li>
              <li>
                Prepare contingency plans for handling potential overflow in
                epic activities.
              </li>
            </oi>
          </section>
        </div>
      </Drawer>
    </div>
  );
};

export default WorkAllocation;
