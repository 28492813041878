import React, { useContext, useEffect, useState } from "react";
import instance from "../../../../../utils/axiosHelper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CommonTable from "../../../../../components/commonTable";
import RiskWrapper from "../../../../../components/overview/riskWrapper";
import { Alert, Box, Tooltip } from "@mui/material";
import { datePayload } from "../../../../../utils/moduleTabApi";
import CommonPopup from "../../../../../components/commonPopup";
import TrendWithCategoryAxis from "../../../../../components/trendGraph/trendWithCategory";
import { format, parseISO } from "date-fns";
import { ceil } from "../../../../../utils/changeDecimal";
import "../../health/Styles.css";
import HealthColorWrapper from "../../../../../components/overview/healthColorWrapper";
import InfoIcon from "@material-ui/icons/Info";
import { GlobalData } from "../../../../../context/globalData";
import { definations } from "../../../../../utils/defination";

function Communication({
  date,
  showTrend,
  setShowTrend,
  getProcessStatus,
  recommendation,
}) {
  const [communication, setCommunication] = useState([]);
  const [trendData, setTrendData] = useState([]);
  const { projectId } = useParams();
  const { setIsDefinaionPopup, setDefinationKPI } = useContext(GlobalData);

  const [columns] = useState([
    { title: "Name", field: "name" },
    {
      title: "Health Score (%)",
      field: "HealthScore",
      render: (row) => ceil(row.HealthScore * 100),
    },
    {
      title: "Health Status",
      field: "HealthStatus",
      render: (row) => <HealthColorWrapper status={row.HealthStatus} />,
    },
    {
      title: "Submitter",
      field: "HealthStatus",
      width: '200px',
      render: (row) => (
        <>
          <ul>
            {row?.submitter?.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: "Reviewer",
      field: "HealthStatus",
      render: (row) => (
        <>
          <ul>
            {row?.reviewer?.map((item) => (
              <li>{item}</li>
            ))}
          </ul>
        </>
      ),
    },
    {
      title: (
        <>
          Pull Request Review Time (Days)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Pull Request Review Time (Days)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "prReviewTime",
      render: (row) => (
        <Tooltip
          title={definations?.[
            "Pull Request Review Time (Days)"
          ]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {ceil(row.prReviewTime)}
        </Tooltip>
      ),
    },

    {
      title: (
        <>
          Avg Code Churn (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Code Churn (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "avgCodeChurn",
      render: (row) => (
        <Tooltip
          title={definations?.["Code Churn (%)"]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {ceil(row.avgCodeChurn)}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Merged Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Merged Pull Request (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "firstTimePassPercent",
      render: (row) => (
        <Tooltip
          title={definations?.["Merged Pull Request (%)"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >
          {ceil(row.firstTimePassPercent)}
        </Tooltip>
      ),
    },
    {
      title: "Submitted Pull Request (%)",
      field: "submittedPrPercent",
      render: (row) => ceil(row.submittedPrPercent),
    },
    {
      title: (
        <>
          First Time (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("First Time pass issue");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "mergedPrPercent",
      render: (row) => (
        <Tooltip
          title={definations?.["First Time pass issue"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >
          {ceil(row.mergedPrPercent)}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Review Depth
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Review Depth");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "prDepth",
      render: (row) => (
        <Tooltip
          title={definations?.["Review Depth"]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {ceil(row.prDepth)}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Comment Addressed (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Comment Addressed (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "commentAddressed",
      render: (row) => (
        <Tooltip
          title={definations?.["Comment Addressed (%)"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >
          {ceil(row.commentAddressed)}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Pull Request Has Comment (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Pull Request Has Comment (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "prHasCommentPercent",
      render: (row) => (
        <Tooltip
          title={definations?.["Pull Request Has Comment (%)"]?.benchmark?.map(
            (item) => (
              <ul className="pt-2 mb-0">
                {Array?.isArray(item) ? (
                  <ul>
                    {item?.map((i) => (
                      <li>{i || "N.A."}</li>
                    ))}
                  </ul>
                ) : (
                  <li>{item || "N.A."}</li>
                )}
              </ul>
            )
          )}
          arrow
          placement="top"
        >
          {ceil(row.prHasCommentPercent)}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Receptiveness (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Receptiveness (%)");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "prAddressedPercent",
      render: (row) => (
        <Tooltip
          title={definations?.["Receptiveness (%)"]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {ceil(row.prAddressedPercent)}
        </Tooltip>
      ),
    },
    {
      title: (
        <>
          Reviewed Pull Request (%)
          <span
            onClick={() => {
              setIsDefinaionPopup(true);
              setDefinationKPI("Unreviewed PRs");
            }}
          >
            <InfoIcon style={{ color: "gray", marginLeft: "8px" }} />
          </span>
        </>
      ),
      field: "mergeReviewPr",
      render: (row) => (
        <Tooltip
          title={definations?.["Unreviewed PRs"]?.benchmark?.map((item) => (
            <ul className="pt-2 mb-0">
              {Array?.isArray(item) ? (
                <ul>
                  {item?.map((i) => (
                    <li>{i || "N.A."}</li>
                  ))}
                </ul>
              ) : (
                <li>{item || "N.A."}</li>
              )}
            </ul>
          ))}
          arrow
          placement="top"
        >
          {ceil(row.mergedReviwedPrPercent)}
        </Tooltip>
      ),
    },
    // ==============================================================================
    // {
    //   title: "Completed Issues",
    //   field: "completedIssues",
    //   render: (row) => ceil(row.completedIssues),
    // },

    // {
    //   title: "Pull Request Count",
    //   field: "prCount",
    //   render: (row) => ceil(row.prCount),
    // },

    // {
    //   title: "Receptiveness ",
    //   field: "receptiveNess",
    //   render: (row) => ceil(row.receptiveNess),
    // },
    // {
    //   title: "Reviewed Pull Request ",
    //   field: "reviewedPr",
    //   render: (row) => ceil(row.reviewedPr),
    // },

    // {
    //   title: "UnReviewed Pull Request ",
    //   field: "unReviewedPr",
    //   render: (row) => ceil(row.unReviewedPr),
    // },
  ]);

  useEffect(() => {
    const dateUrl = datePayload(date);
    getProcessStatus("Communication", date);
    instance
      .get(`/api/v2/cm/communications/${projectId}?${dateUrl}`)
      .then((res) => {
        const data = res.data.filter((e) => e.name !== null);
        setCommunication(data);
      })
      .catch((err) => {
        console.error(err);
      });

    instance
      .get(`/api/v2/cm/trend/project/${projectId}?${dateUrl}`)
      .then((res) => {
        const data = res.data.map((e) => {
          const originalDate = parseISO(e.Date);
          return {
            ...e,
            Date: format(originalDate, "MMM yy"),
          };
        });
        setTrendData(data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [date]);

  const [visibleColumns, setVisibleColumns] = useState(columns.slice(0, 5));
  const [showViewMore, setShowViewMore] = useState(true);

  const handleViewMore = () => {
    const currentLength = visibleColumns.length;
    const nextVisibleColumns = columns.slice(currentLength, currentLength + 5);
    setVisibleColumns([...visibleColumns, ...nextVisibleColumns]);

    if (currentLength + 5 >= columns.length) {
      setShowViewMore(false);
    }
  };

  return (
    <div>
      <CommonPopup
        open={showTrend}
        setOpen={setShowTrend}
        width={900}
        title={"Trend"}
      >
        <Box padding={3} width={850}>
          <TrendWithCategoryAxis body={"helthTrend"} data={trendData} />
        </Box>
      </CommonPopup>
      {recommendation?.status === "GOOD" ? null : (
        <Alert sx={{ mb: 3 }} severity="info">
          <b>Insights : </b>
          Based on our analysis, you need to improve on{" "}
          <b>
            {recommendation?.recommendation?.slice(0, -1).replaceAll(",", ", ")}
          </b>{" "}
          so that you can make your Communication healthy.
        </Alert>
      )}
      <div className="healthTable">
        <CommonTable columns={visibleColumns} data={communication} />
      </div>
      {showViewMore && (
        <div
          style={{
            color: "rgb(65, 132, 142)",
            textAlign: "end",
            cursor: "pointer",
          }}
          onClick={handleViewMore}
          className="pt-2"
        >
          ...View More Column
        </div>
      )}
    </div>
  );
}

export default Communication;
