import React, { useEffect, useRef, useState } from "react";
import SelectField from "../../../components/select/selectField";
import instance from "../../../utils/axiosHelper";
import { Box, Card, styled, Tooltip, tooltipClasses } from "@mui/material";
import DependCard from "./dependCard";
import nextArrow from "../../../assets/nextArrow.png";

const DependencyRequire = () => {
  const [allIssueList, setAllIssueList] = useState([]);
  const [allDependency, setAllDependency] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState();
  const [selectedDependency, setSelectedDependency] = useState([]);
  const divRef = useRef(null);
  const [inWordAndOutWordKeys, setInWordAndOutWordKeys] = useState({
    in: "",
    out: "",
  });


  // get all dependencies from backend
  useEffect(() => {
    let url = `/api/v2/requirementAnalytic/dependencyMetrics`;
    instance
      .get(url)
      .then((response) => {
        const data = response?.data;
        const issueList = data?.map((e) => {
          return e?.Key;
        });
        setAllIssueList(issueList);
        setAllDependency(data);
      })
      .catch((error) => console.log("error", error));
  }, []);

  // filter dependencies
  useEffect(() => {
    const getSelectedDependentIssue = allDependency.filter(
      (item) => item.Key === selectedIssue
    );
    setSelectedDependency(getSelectedDependentIssue);
  }, [allDependency, selectedIssue]);

  // scroll to selected dependency
  useEffect(() => {
    divRef?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  }, [selectedDependency]);

  // create text for arrow
  useEffect(() => {
    let inWordKey = [];
    let outWordKey = [];
    selectedDependency?.forEach((item) =>
      item?.Data?.forEach((innerItem) => {
        if (innerItem?.IssueLinks?.inwardIssue) {
          inWordKey.push(innerItem?.IssueLinks?.inwardIssue?.key);
        } else {
          outWordKey.push(innerItem?.IssueLinks?.outwardIssue?.key);
        }
      })
    );
    setInWordAndOutWordKeys({
      in: inWordKey.join(", "),
      out: outWordKey.join(", "),
    });
  }, [selectedDependency]);
 
  return (
    <Card className="mt-4 p-4">
      <h4>Dependency Chart</h4>
      {/* Select Issue Field */}
      <SelectField
        render={allIssueList}
        value={selectedIssue}
        setValue={(e) => setSelectedIssue(e)}
        placeholder="Select Issue..."
      />

      {/* Dependency Chart Design */}
      {selectedIssue ? (
        <>
          {selectedDependency?.map((item) => {
            let isInWord = 0;
            let isOutWord = 0;
            return (
              <div className="d-flex justify-content-around align-items-center">
                {/* Inward Dependant card */}
                <div>
                  {item?.Data?.map((inward, index) => {
                    // code for next arrow
                    inward?.IssueLinks?.inwardIssue
                      ? (isInWord = isInWord + 1)
                      : (isInWord = isInWord + 0);

                    // code for next arrow
                    inward?.IssueLinks?.outwardIssue
                      ? (isOutWord = isOutWord + 1)
                      : (isOutWord = isOutWord + 0);
                    return (
                      <div key={index}>
                        {inward?.IssueLinks?.inwardIssue && (
                          <div style={{ width: "400px" }}>
                            <DependCard
                              issueName={inward?.IssueLinks?.inwardIssue?.key}
                              status={
                                inward?.IssueLinks?.inwardIssue?.fields?.status
                                  ?.name
                              }
                              description={
                                inward?.IssueLinks?.inwardIssue?.fields?.summary
                              }
                              issueLink={inward?.IssueLinks?.inwardIssue?.self}
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                {isInWord ? (
                  <Box className="p-4" width={200}>
                    <Tooltip
                      title={<Box fontSize={"14px"}>{`${inWordAndOutWordKeys?.in} tickets depend on ${item?.Key}.`}</Box>}
                      placement="top"
                      arrow
                    >
                      <img src={nextArrow} alt="next" className="img-fluid" />
                    </Tooltip>
                  </Box>
                ) : null}
                {/* Main Dependant card */}
                <div style={{ width: "400px" }} ref={divRef}>
                  <DependCard
                    issueName={item?.Key}
                    status={item?.Data[0].Status}
                    description={item?.Data[0].Summary}
                    issueLink={item?.Data[0].Url}
                  />
                </div>
                {isOutWord ? (
                  <Box className="p-4" width={200}>
                    <Tooltip
                      title={<Box fontSize={"14px"}>{`${item?.Key} ticket depend on ${inWordAndOutWordKeys?.out}.`}</Box>}
                      placement="top"
                      arrow
                    >
                      <img src={nextArrow} alt="next" className="img-fluid" />
                    </Tooltip>
                  </Box>
                ) : null}

                {/* Outward Dependant card */}
                <div>
                  {item?.Data?.map((outWard, index) => {
                    return (
                      <div key={index}>
                        {outWard?.IssueLinks?.outwardIssue && (
                          <div style={{ width: "400px" }}>
                            <DependCard
                              issueName={outWard?.IssueLinks?.outwardIssue?.key}
                              status={
                                outWard?.IssueLinks?.outwardIssue?.fields
                                  ?.status?.name
                              }
                              description={
                                outWard?.IssueLinks?.outwardIssue?.fields
                                  ?.summary
                              }
                              issueLink={
                                outWard?.IssueLinks?.outwardIssue?.self
                              }
                            />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <div className="text-center my-4">
          Please select issue type for view the dependencies....
        </div>
      )}
    </Card>
  );
};

export default DependencyRequire;
